let scrollWidthFunc = () => {
    let scrollWidth = window.innerWidth - document.body.clientWidth;
    document.querySelector('html').style.paddingRight = scrollWidth + 'px';
    document.querySelector('header').style.paddingRight = scrollWidth + 'px';
    
}

const burgerMenu = document.querySelector('.header-burger-js');
if (burgerMenu) {
  const headerMobile = document.querySelector('.header__wrapper-js');
  const svg = burgerMenu.querySelector('svg');
  const headerTop =  document.querySelector('.header__top');
  const headerMobileItem = document.querySelector('.header__mobile');
  burgerMenu.addEventListener("click", () => {
      let headerTopHeight = headerTop.offsetHeight;
      if (headerMobile.classList.contains("active")) {
          headerMobile.removeAttribute('style');
      }
      else {
        headerMobile.style.maxHeight = 'calc(100vh - ' + headerMobileItem.offsetHeight + 'px - ' + headerTopHeight + 'px)'
      }
      headerMobile.classList.toggle("active");
      burgerMenu.classList.toggle("active");
      svg.classList.toggle("active");
      document.querySelector('body').classList.toggle('burger-lock');
 
  });
}


const headerPlashkaBtn = document.querySelector('.header__plashka-close-js');
if (headerPlashkaBtn) {
    headerPlashkaBtn.addEventListener('click', ()=> {
        headerPlashkaBtn.closest('.header__plashka').classList.add('hide');
    })
}
const counterStocks = document.querySelector('.stock-date-js');
const currentCounterStocks = document.querySelector('.stock-future-js');
if (counterStocks && currentCounterStocks) {
    function timeStocks(countDownDate, intervalName) {
        let now = new Date().getTime();
        let distance = countDownDate - now;
        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);
        counterStocks.innerHTML = days + "д: " + hours + "ч: " +
        minutes + " мин: " + seconds + " сек ";
        if (intervalName) {
            if (distance < 0) {
                clearInterval(intervalName);
                document.querySelector(".stock-date-js").innerHTML = "Завершена";
            }
        }
    }
let date = new Date();
date.setDate(date.getDate() + 5);
const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];
const monthNamesRus = ["января", "февраля", "марта", "апреля", "мая", "июня",
    "июля", "августа", "сентября", "октября", "ноября", "декабря"
];
let monthNumber =  date.getMonth();
const month = monthNames[monthNumber];
currentCounterStocks.innerHTML = date.getDate() + ' ' + monthNamesRus[monthNumber];
const countDownDate = new Date(month + ' ' + date.getDate() + ', ' + date.getFullYear() + ' 00:00:00').getTime();
timeStocks(countDownDate, '');
    const x = setInterval(function () {
        timeStocks(countDownDate, x);
    }, 1000);

}

const headerMenus = document.querySelectorAll('.header-menu-js');
if (headerMenus.length > 0) {
    const allBtns = document.querySelectorAll('.header-menu-btn-js');
    const allItems = document.querySelectorAll('.header-menu-item-js');
    headerMenus.forEach(element => {
        const btn = element.querySelector('.header-menu-btn-js');
        const item = element.querySelector('.header-menu-item-js');
        btn.addEventListener('click', ()=> {
            if (btn.classList.contains('active')) {
                btn.classList.remove('active');
                item.classList.remove('active');
                if (btn.querySelector('svg')) {
                    btn.querySelector('svg').classList.remove('active');
                }
                document.querySelector('body').classList.remove('menu-lock');
                document.querySelector('html').removeAttribute('style');
                document.querySelector('header').removeAttribute('style');
            }
            else {
                allBtns.forEach(elem => {
                    elem.classList.remove('active');
                    if (elem.querySelector('svg')) {
                        elem.querySelector('svg').classList.remove('active');
                    }
                })
                allItems.forEach(elem => {
                    elem.classList.remove('active');
                })
                btn.classList.add('active');
                item.classList.add('active');
                if (btn.querySelector('svg')) {
                    btn.querySelector('svg').classList.add('active');
                }
                scrollWidthFunc();
                if (!document.querySelector('body').classList.contains('burger-lock')) {
                    document.querySelector('body').classList.add('menu-lock');
                }
             
        
            }
        })
    });
}
/* sliders */
const introSliderCheck = document.querySelectorAll('.intro');
['resize', 'load'].forEach((event) => {
    window.addEventListener(event, function () {

            if (introSliderCheck.length > 0) {
                introSliderCheck.forEach((slider) => {
                    if (slider.querySelector('.intro-swiper-js')) {
                        
                        if (window.innerWidth > 1023) {
                        const sliderIntro =  slider.querySelector('.intro-swiper-js');
                        const sliderWrapper = slider.querySelector('.intro-swiper-wrapper-js');
                        const introSlides = slider.querySelectorAll('.intro-swiper-slide-js');
    
                        sliderIntro.classList.add('swiper');
                        sliderWrapper.classList.add('swiper-wrapper');
                        introSlides.forEach((slide) => {
                            slide.classList.add('swiper-slide');
                        });
                        
                        var swiperintro = new Swiper(slider.querySelector('.swiper'), {
                            direction: 'horizontal',
                            navigation: {
                                nextEl: slider.querySelector('.slider-button_next'),
                                prevEl: slider.querySelector('.slider-button_prev'),
                            },
                            slidesPerView: 1,
                            effect: "creative",
                            creativeEffect: {
                                prev: {
                                    translate: ["-20%", 0, -1],
                                },
                                next: {
                                    translate: ["100%", 0, 0],
                                },
                            },
                            
                            
                        });
                       // swiperintro.detachEvents(); 
                       // swiperintro.destroy(); 
                        }
                        else {    
                          
                          
                            const sliderIntro =  slider.querySelector('.intro-swiper-js');
                            const sliderWrapper = slider.querySelector('.intro-swiper-wrapper-js');
                            const introSlides = slider.querySelectorAll('.intro-swiper-slide-js');
                            sliderIntro.classList.remove('swiper');
                            sliderWrapper.classList.remove('swiper-wrapper');

                            const deleteItems = sliderIntro.querySelectorAll('.swiper-notification');
                            if (deleteItems.length > 0) {
                                deleteItems.forEach(elem => {
                                    elem.remove();
                                })
                            }
                            introSlides.forEach((slide) => {
                                slide.removeAttribute("style");
                            });
                        }
                    
                    }
                });
            }
     
    });
});



const faqSliderCheck = document.querySelectorAll('.faq');
if (faqSliderCheck.length > 0) {
    faqSliderCheck.forEach((slider) => {
        const swiperFaq = new Swiper(slider.querySelector('.swiper'), {
            direction: 'horizontal',
            navigation: {
                nextEl: slider.querySelector('.slider-button_next'),
                prevEl: slider.querySelector('.slider-button_prev'),
            },
            slidesPerView: 1.2,
            spaceBetween: 20,
            effect: "slide",
            breakpoints: {
                1023: {
                    slidesPerView: 2,
                },
            }
        });
    })
}

const diplomsSliderCheck = document.querySelectorAll('.diploms');
if (diplomsSliderCheck.length > 0) {
    diplomsSliderCheck.forEach((slider) => {
        const swiperDiploms = new Swiper(slider.querySelector('.swiper'), {
            direction: 'horizontal',
            navigation: {
                nextEl: slider.querySelector('.slider-button_next'),
                prevEl: slider.querySelector('.slider-button_prev'),
            },
            pagination: {
                el:  slider.querySelector('.diploms__pagination'),
                type: 'bullets',
              },
            slidesPerView: 1.1,
            spaceBetween: 18,
            effect: "slide",
            breakpoints: {
                700: {
                    slidesPerView: 2,
                },
            }
        });
    })
}

const doctorsSliderCheck = document.querySelectorAll('.doctors');
if (doctorsSliderCheck.length > 0) {
    doctorsSliderCheck.forEach((slider) => {
        const swiperDoctors = new Swiper(slider.querySelector('.swiper'), {
            direction: 'horizontal',
            navigation: {
                nextEl: slider.querySelector('.slider-button_next'),
                prevEl: slider.querySelector('.slider-button_prev'),
            },
            slidesPerView: 1.1,
            spaceBetween: 10,
            effect: "slide",
            breakpoints: {
                1140: {
                    slidesPerView: 4,
                },
                900: {
                    slidesPerView: 3,
                },
                650: {
                    slidesPerView: 2,
                },
            }
        });
    })
}

const reviewsSliders = document.querySelectorAll('.reviews');
if (reviewsSliders.length > 0) {
    reviewsSliders.forEach((slider) => {
        const reviewsSliderTHumbCheck = slider.querySelector('.reviews__slider-mini .swiper');
        const swiperReviewsThumb = new Swiper(reviewsSliderTHumbCheck, {
            direction: 'vertical',
            effect: 'slide',
            grabCursor: true,
            spaceBetween: 10,
            slidesPerView: 5,
            watchSlidesProgress: true,
        });
        const reviewsSliderCheck = slider.querySelector('.reviews__slider-main .swiper');
        const swiperReviews = new Swiper(reviewsSliderCheck, {
            direction: 'horizontal',
            thumbs: {
                swiper: swiperReviewsThumb
            },
            grabCursor: true,
            spaceBetween: 10,
            slidesPerView: 1.2,
            effect: "slide",
            breakpoints: {
                1140: {
                    slidesPerView: 1,
                },
            }
        });
    })
};

const articlesSliderCheck = document.querySelectorAll('.articles');
if (articlesSliderCheck.length > 0) {
    articlesSliderCheck.forEach((slider) => {
        const swiperArticle = new Swiper(slider.querySelector('.swiper'), {
            direction: 'horizontal',
            navigation: {
                nextEl: slider.querySelector('.slider-button_next'),
                prevEl: slider.querySelector('.slider-button_prev'),
            },
            spaceBetween: 10,
            effect: "slide",
            slidesPerView: 1.1,
            grid: {
                rows: 1,
                fill: "row"
              },
            scrollbar: {
                el: slider.querySelector('.articles__scrollbar'),
                draggable: true,
              },
          
            breakpoints: {
                1140: {
                    slidesPerView: 3,
                    grid: {
                        rows: 2,
                        fill: "row"
                      },
                },
                800: {
                    slidesPerView: 2,
                    grid: {
                        rows: 1,
                        fill: "row"
                      },
                }
            }
        });
    })
}

const certificatesSliderCheck = document.querySelectorAll('.certificates');
if (certificatesSliderCheck.length > 0) {
    certificatesSliderCheck.forEach((slider) => {
        const swiperCertificates = new Swiper(slider.querySelector('.swiper'), {
            direction: 'horizontal',
            spaceBetween: 10,
            effect: "coverflow",
            slidesPerView: 1.1,
            loop: true,
            speed: 800,
            navigation: {
                nextEl: slider.querySelector('.slider-button_next'),
                prevEl: slider.querySelector('.slider-button_prev'),
            },

            breakpoints: {
                1140: {
                    spaceBetween: 0,
                    slidesPerView: 2.1,
                    initialSlide: 1,
                    coverflowEffect: {
                        rotate: 0,
                        scale: 0.55,
                        stretch: 0,
                        depth: 250,
                        modifier: 1,
                        slideShadows: false,
                    },
          
            centeredSlides: true,
                },
            },
            coverflowEffect: {
                rotate: 0,
                scale: 1,
                stretch: 0,
                depth: 0,
                modifier: 1,
                slideShadows: false,
            },
           
        });
    })
}

/* Mask phone */
[].forEach.call(document.querySelectorAll('input[type=tel]'), function (input) {
    let keyCode;
    function mask(event) {
        event.keyCode && (keyCode = event.keyCode);
        let pos = this.selectionStart;
        if (pos < 3) event.preventDefault();
        let matrix = "+7 (___) ___ ____",
            i = 0,
            def = matrix.replace(/\D/g, ""),
            val = this.value.replace(/\D/g, ""),
            new_value = matrix.replace(/[_\d]/g, function (a) {
                return i < val.length ? val.charAt(i++) || def.charAt(i) : a
            });
        i = new_value.indexOf("_");
        if (i != -1) {
            i < 5 && (i = 3);
            new_value = new_value.slice(0, i)
        }
        let reg = matrix.substr(0, this.value.length).replace(/_+/g,
            function (a) {
                return "\\d{1," + a.length + "}"
            }).replace(/[+()]/g, "\\$&");
        reg = new RegExp("^" + reg + "$");
        if (!reg.test(this.value) || this.value.length < 5 || keyCode > 47 && keyCode < 58) this.value = new_value;
        if (event.type == "blur" && this.value.length < 5) this.value = ""
    }

    input.addEventListener("input", mask, false);
    input.addEventListener("focus", mask, false);
    input.addEventListener("blur", mask, false);
    input.addEventListener("keydown", mask, false)

});
/* End Mask phone */

// Popups

 function popupClose(popupActive) {
    popupActive.classList.remove('open');
    setTimeout(() => {
        if (!popupActive.classList.contains('open')) {
            popupActive.classList.remove('active');
        }
    }, 400);
    document.body.classList.remove('lock');
    document.querySelector('html').style.paddingRight = 0;
    document.querySelector('html').classList.remove('lock');
    document.querySelector('header').removeAttribute('style');
    
    
}
const popupOpenBtns = document.querySelectorAll('.popup-btn');
const popups = document.querySelectorAll('.popup');
const originalTitlePopup2 = document.querySelector('.original-title').innerHTML;
const closePopupBtns = document.querySelectorAll('.close-popup');
closePopupBtns.forEach(function (el) {
    el.addEventListener('click', function (e) {
        popupClose(e.target.closest('.popup'));
    });
});
popupOpenBtns.forEach(function (el) {
    el.addEventListener('click', function (e) {
        e.preventDefault();
        const path = e.currentTarget.dataset.path;
        const currentPopup = document.querySelector(`[data-target="${path}"]`);
        if (currentPopup) {
            popups.forEach(function (popup) {
                popupClose(popup);
                popup.addEventListener('click', function (e) {
                    if (!e.target.closest('.popup__content')) {
                        popupClose(e.target.closest('.popup'));
                    }
                });
            });
            currentPopup.classList.add('active');
            setTimeout(() => {
                currentPopup.classList.add('open');
            }, 10);
            if (currentPopup.getAttribute('data-target') == 'popup-change') {
            
                let originaTitle = currentPopup.querySelector('.original-title');
                console.log(el);
                if (el.classList.contains('change-item__btn')) {
                   
                    if (el.classList.contains('doctor__btn-js')) {
                        let currentItem = el.closest('.change-item');
                        let currentTitile = currentItem.querySelector('.change-item__title');
                        originaTitle.innerHTML = 'Записаться на приём к врачу: ' + currentTitile.innerHTML
                    }
                    else {
                        if (el.classList.contains('change-item__btn_current')) {
                            originaTitle.textContent = el.textContent;
                        }
                        else {
                            let currentItem = el.closest('.change-item');
                            let currentTitile = currentItem.querySelector('.change-item__title');
                            originaTitle.innerHTML = currentTitile.innerHTML
                        }
                    }
                }
                else {
                    originaTitle.innerHTML = originalTitlePopup2;
                }
            }
            scrollWidthFunc();
            document.querySelector('html').classList.add('lock');
        }
    });
});

/* end popups */

/* tabs */ 
const tabs = document.querySelectorAll('.tabs');
if (tabs.length > 0) {
    tabs.forEach(elem => {
        const buttons = elem.querySelectorAll('.tab');
        const contents =  elem.querySelectorAll('.tab-content');
        const activeTab = elem.querySelector('.tab-active');
        const wrapper = elem.querySelector('.tab-wrapper')
        if (buttons.length > 0 && contents.length > 0) {
            for (let i = 0; i < buttons.length; i++) {
                buttons[i].addEventListener('click', ()=> {
                    if (!buttons[i].classList.contains('active')) {
                        buttons.forEach(element => {
                            element.classList.remove('active');
                        });
                        contents.forEach(element => {
                            element.classList.remove('active');
                        });
                        buttons[i].classList.add('active');
                        contents[i].classList.add('active');
                        if (elem.querySelector('.tabs-counter')) {
                            elem.querySelector('.tabs-counter').innerHTML = contents[i].children.length;
                        }
                        if (activeTab && wrapper) {
                            buttons.forEach(element => {
                                element.classList.remove('tab-hide');
                            });
                            buttons[i].classList.add('tab-hide');
                            activeTab.innerHTML = buttons[i].textContent;
                            wrapper.classList.remove('active');
                            activeTab.classList.remove('active');
                        }
                    }
                })
            }
            buttons[0].click();

            if (activeTab) {
                activeTab.innerHTML =  buttons[0].textContent;
                buttons[0].classList.add('tab-hide');
                activeTab.addEventListener('click', ()=> {
                    if (wrapper) {
                        wrapper.classList.toggle('active');
                        activeTab.classList.toggle('active');
                    }
                })
            }
        }
       
    })
}

// animation 
const animationItems = document.querySelectorAll('.animation-item');
if (animationItems.length > 0) {
  function onEntry(e) {
      e.forEach(e => {
          e.isIntersecting && e.target.classList.add("animation-active")
      }
      )
  }
  let options = {
      threshold: [.5]
  }, observer = new IntersectionObserver(onEntry, options)
  for (let e of animationItems)
      observer.observe(e);
}
// end animation
Fancybox.bind("[data-fancybox]", {
    // Your custom options
  });



  /* yandex map */
  const map = document.querySelectorAll('#map');
  if (map.length > 0) {
    function onEntryMap(e) {
      e.forEach(e => {
        e.isIntersecting && loadMap() && initMap();
      })
    }
    let options = {
      threshold: [0.5],
    },
      observer = new IntersectionObserver(onEntryMap, options)
    for (let e of map) observer.observe(e)
  }
  function loadMap() {
    if (!document.querySelector('[src="https://api-maps.yandex.ru/2.1/?lang=ru_RU"]')) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://api-maps.yandex.ru/2.1/?lang=ru_RU';
      script.onload = initMap;
      document.head.appendChild(script);
    }
  }
  function initMap() {
    ymaps.ready(function () {
      const myMap = new ymaps.Map('map', {
        center: [47.228163, 39.714641],
        zoom: 15,
        controls: []
      });
      var myPlacemark = new ymaps.Placemark([47.228163, 39.714641],
        {
        balloonContentHeader: "<p class='yandex-city'>Ростов-на-Дону</p>",
        balloonContentBody: "<p class='yandex-clinic'>«Название клиники»</p>",
        hintContent: "Посмотреть Адрес"
      },{
        iconLayout: 'default#image',
        iconImageHref: '/assets/img/icons/map-pin.svg',
        iconImageSize: [36, 36],
    });

      myMap.geoObjects.add(myPlacemark);
      myMap.behaviors.disable(['scrollZoom']);
    });
  }
  /* end yandex map */
const panelItems = document.querySelectorAll('.panel-items');
if (panelItems.length > 0) {
    panelItems.forEach((elem) => {
  let panelItem = elem.querySelectorAll('.panel-item');
  if (panelItem.length > 0) {
    panelItem.__proto__.forEach = [].__proto__.forEach;
    let activePanel;
    panelItem.forEach(function (item, i, panelItem) {
        item.addEventListener('click', function (e) {
            this.classList.add('active');
            this.nextElementSibling.classList.add('active');
            if (activePanel) {
                activePanel.classList.remove('active');
                activePanel.nextElementSibling.classList.remove('active');
            }
            activePanel = (activePanel === this) ? 0 : this;
        });
    });
  }   
})
}

  const hideItems = document.querySelectorAll('.hide-items');
if (hideItems.length > 0) {
    hideItems.forEach((elem) => {
        const hideItem = elem.querySelectorAll('.hide-item');
        const hideTitles = elem.querySelectorAll('.hide-item__btn');
        const hideContents = elem.querySelectorAll('.hide-item__content');
        hideItem.forEach((item) => {
            let title = item.querySelector('.hide-item__btn');
            let content = item.querySelector('.hide-item__content');
            title.addEventListener('click', () => {
                if (title.classList.contains('active')) {
                    title.classList.remove('active');
                    content.classList.remove('active');
                    content.removeAttribute('style');
                }
                else {
                    hideTitles.forEach((element) => {
                        element.classList.remove('active');
                    })
                    hideContents.forEach((element) => {
                        element.classList.remove('active');
                        element.removeAttribute('style');
                    })
                    let height = content.querySelector('.hide-item__height').offsetHeight;
                    title.classList.add('active');
                    content.classList.add('active');
                    content.style.height = height + 'px';
                }
            })
        })
    })
}

const buttonBadseeDuplicate = document.querySelector('.badseeDuplicate');
if (buttonBadseeDuplicate) {
    buttonBadseeDuplicate.addEventListener('click', () => {
        if (document.getElementById("specialButton")) {
            document.getElementById("specialButton").click();
        } 
    })
}

// navigation
const articleNavigation = document.querySelector('.content-list-js');

if (articleNavigation) {
    const jsScrollBlockList = document.querySelectorAll('.text__content h1, .text__content h2, .text__content h3');
    if (jsScrollBlockList.length > 0) {
        for (let i = 0; i < jsScrollBlockList.length; i += 1) {
            const jsScrollBlock = jsScrollBlockList[i];
            const titleBlock = jsScrollBlock.textContent;
            const articleNavigationList = document.querySelector('.content-list-js ul');
            const articleNavigationItem = document.createElement('li');
            const articleNavigationLink = document.createElement('a');
            articleNavigationItem.classList.add('content__list-item');
            articleNavigationLink.classList.add('content__list-link');
            for (let j = i; j >= 0; j -= 1) {
                const prevTitleLevel = jsScrollBlockList[j].tagName.replace('H', '');
                const activeTitleLevel = jsScrollBlock.tagName.replace('H', '');
                if (prevTitleLevel < activeTitleLevel) {
                    articleNavigationItem.classList.add('content__list-subitem');
                }
            }
            jsScrollBlock.setAttribute('id', `${i}`)
            articleNavigationLink.setAttribute('href', `#${i}`);
            articleNavigationLink.textContent = ' ' + titleBlock;
            articleNavigationItem.append(articleNavigationLink);
            articleNavigationList.append(articleNavigationItem);
        }


    } else {
        articleNavigation.querySelector('ul').remove();
    }
}

// end navigation

const filters = document.querySelectorAll('.filter-js');
if (filters) {
    filters.forEach(filter => {
        const selects = filter.querySelectorAll('.filter-select-js');
        const items = filter.querySelectorAll('.filter-item-js');
        const counter = filter.querySelector('.filter-counter-js'); 
        for (let i = 0; i < selects.length; i++) {
            selects[i].addEventListener('change', ()=> {
            const value = selects[i].value;
            items.forEach(item =>  { 
                const atribute = item.querySelector(`[data-filterItem-${i}]`);
                if (atribute && atribute.textContent != '') {
                   if (value != atribute.textContent.trim()) {
                    item.classList.add('hide');
                   }
                   else {
                    let checkedOtherSelect = false;
                    for (let j = 0; j < selects.length; j++) {
                        if ((i != j) && (selects[j].value != 'default')) {
                            if (item.querySelector(`[data-filterItem-${j}]`)) {
                                if (!(selects[j].value == item.querySelector(`[data-filterItem-${j}]`).textContent.trim())) {
                                    checkedOtherSelect = true;
                                    break;
                                }
                            }
                        }
                    }
                    if (checkedOtherSelect == false) {
                        item.classList.remove('hide');
                    }
                   }
                }
                else {
                    item.classList.add('hide');
                }
            });
            if (counter) {
                const counterItems = filter.querySelectorAll('.filter-item-js:not(.hide)');
                counter.innerHTML = counterItems.length
            }
        })
        }
        if (counter) {
            counter.innerHTML = items.length
        }
    });
}

document.querySelectorAll('a[href^="#"').forEach(link => {

    link.addEventListener('click', function (e) {
        e.preventDefault();

        let href = this.getAttribute('href').substring(1);
        const scrollTarget = document.getElementById(href);
        const topOffset = 180;
        const elementPosition = scrollTarget.getBoundingClientRect().top;
        const offsetPosition = elementPosition - topOffset;

        window.scrollBy({
            top: offsetPosition,
            behavior: 'smooth'
        });
    });
});

function search(wrapper) {
    let inputSearch = wrapper.querySelector(".search-input-js");

    if (inputSearch) {
         let filter = inputSearch.value.toUpperCase();
         let items = wrapper.querySelectorAll(".search-item-js");
        for (let i = 0; i < items.length; i++) {
            let name = items[i].querySelector(".search-elem-js");
            if (name.innerHTML.toUpperCase().indexOf(filter) > -1) {
                items[i].removeAttribute('style');
            } else {
                items[i].style.display = "none";
            }
        }
        
    }
}
const searchs = document.querySelectorAll('.search-js');
if (searchs.length > 0) {
    searchs.forEach(wrapper => {
        let inputSearch = wrapper.querySelector(".search-input-js");
        const hideElement = wrapper.querySelector(".search-start-hide-js")

        if (inputSearch) {
            inputSearch.addEventListener('keyup', ()=>{
                if (hideElement) {
                    console.log(12);
                    hideElement.remove();
                }
                     let filter = inputSearch.value.toUpperCase();
                     let items = wrapper.querySelectorAll(".search-item-js");
                    for (let i = 0; i < items.length; i++) {
                        let name = items[i].querySelector(".search-elem-js");
                        if (name.innerHTML.toUpperCase().indexOf(filter) > -1) {
                            items[i].removeAttribute('style');
                        } else {
                            items[i].style.display = "none";
                        }
                    }
            });
        }
    })
}
const articlesFilter = document.querySelectorAll('.articles-filter-js');
if (articlesFilter.length > 0) {
    articlesFilter.forEach(elem => {
        const btns = elem.querySelectorAll('.articles-filter-tab-js');
        const items = elem.querySelectorAll('.articles-filter-item-js');
        const counter = elem.querySelector('.counter-js');
        if (counter) {
            counter.innerHTML = items.length;;
        }
        const activeTab = elem.querySelector('.tab-active');
        const wrapper = elem.querySelector('.tab-wrapper')
        btns.forEach(btn => {
            btn.addEventListener('click', ()=> {
                if (!btn.classList.contains('active')) {
                    btns.forEach(item => {
                        item.classList.remove('active');
                    })
                    btn.classList.add('active');
                    let a = 0;
                    items.forEach(element => {
                        const tags = element.querySelector('.articles-filter-list-js');
                        if (tags) {
                            let tagsArray = [];
                            const tag = tags.querySelectorAll('li');
                            tag.forEach(item =>{
                                tagsArray.push(item.textContent.trim());
                                return tagsArray;
                            });
                            if (tagsArray.indexOf(btn.textContent.trim()) >= 0) {
                                element.classList.remove('hide');
                                a++;
                            }
                            else {
                                element.classList.add('hide')
                            }
                            return a;
                        }
                    })
                    if (counter) {
                        counter.innerHTML = a;
                    }
                    if (activeTab && wrapper) {
                        btns.forEach(element => {
                            element.classList.remove('tab-hide');
                        });
                        btn.classList.add('tab-hide');
                        activeTab.innerHTML = btn.textContent;
                        wrapper.classList.remove('active');
                        activeTab.classList.remove('active');
                    }
                }
            })
        })
        if (activeTab) {
            activeTab.innerHTML =  btns[0].textContent;
            btns[0].classList.add('tab-hide');
            activeTab.addEventListener('click', ()=> {
                if (wrapper) {
                    wrapper.classList.toggle('active');
                    activeTab.classList.toggle('active');
                }
            })
        }
    })
}